import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useEffect, useState } from "react"
import { BranchLoader } from "./DataLoader"
import apis from "../api"
import { getUserSession } from "./Common"


const BranchSelector = (props) => {
    const { defaultValue,onChange,branches,name,label } = props
    const [_branches,setBranches] = useState(branches)
    const [error,setError] = useState()
    const ss = getUserSession(apis)
    useEffect(()=>{
        if(!_branches) {
            BranchLoader(apis,(data)=>{setBranches(data)},setError)
        }
    },[])
    return <FormControl fullWidth sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label} {error && <font style={{color:'red'}}>{error}</font>}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id={name}
            onChange={(e) => { 
                let id = e.target.value; 
                let choice = false
                if(id>0) {
                    for(let i=0;i<_branches.length;i++) {
                        if(id == _branches[i].id) {
                            onChange && onChange(name,_branches[i])
                            choice = true
                            break
                        }
                    }
                }
                if(!choice) {
                    onChange && onChange(name,{id:0})
                }
            }}
            defaultValue={defaultValue}
            label={label}>
            <MenuItem value={0}>All Locations</MenuItem>
            {_branches && _branches.map((p, idx) => {                       
                return p.status == 1 && <MenuItem key={idx} value={p.id}>{p.name}</MenuItem>
            })}
        </Select>
    </FormControl>
}
export default BranchSelector