const { apiResult, sessionGet, sessionSet } = require("./Common")

const getItemFromObjectList = (datas,id)=>{
    for(let i=0;i<datas.length;i++) {
        if(datas[i].id == id) return datas[i]
    }
    return undefined
}

const BranchLoader = (apis,callback,setError,refresh=false) => {
    let datas = sessionGet('merchant_branchs')
    if(datas && !refresh) {
        callback && callback(datas)
    }else {
        apis.branchGet().then(ret=>{
            apiResult(ret,(datas)=>{
                sessionSet('merchant_branchs',datas)
                callback && callback(datas)
            },setError)
        })
    }
}

export {
    getItemFromObjectList,
    BranchLoader,
}