import { useEffect } from "react"
import { useState } from "react"
import apis from "../../api"
import { apiResult,  getCurrentMonth01, getUserSession } from "../../Utils/Common"
import { Paper,Toolbar, Grid,  FormControl,  Alert, TextField, Button, Box, } from '@mui/material';
import MyTable from "../../Component/MyTable";
import { SingleSelector, Title } from "../../Component/MuiEx";

import BranchSelector from "../../Utils/BranchSelector";
const MemberReport = () => {
    const [members,setMembers] = useState([])    
    const [error, setError] = useState()    
    const currentYear = ()=>{
        let d = new Date()
        let m = d.getMonth() + 1
        let y = d.getFullYear()
        y = m<9?y-1:y        
        return y+"-09-01"
    }
    const [fields,setFields] = useState({from:getCurrentMonth01(),to:new Date().toISOString().split('T')[0],option:1})
    const session = getUserSession(apis)
    const handleOrder = (item)=>{
        const neworder = orderField.order == 'desc'?'asc':'desc'
        setOrderField({...orderField,name:item.name,order:neworder})
        loadData(fields.branch_id,fields.from,fields.to,fields.option,item.name,neworder)
    }
    const [orderField,setOrderField] = useState({fields:['name','parent','minutes'],name:'name',order:'asc'})

    useEffect(()=>{
        loadData(session?.branch_id,getCurrentMonth01(),new Date().toISOString().split('T')[0],1,orderField.name,orderField.order)
    },[])
    const loadData = (branch_id,from,to,option,orderfield,order)=>{        
        apis.getMemberHoursReport(branch_id,from,to,option,orderfield,order).then(ret=>{
            apiResult(ret,data=>{
                setMembers(data)
            },setError)
        })
    }
    const tableHeader = [
        {name:'member_id',showName:'ID'},
        {name:'name',showName:'Member',func:(v,idx,row)=>{
            return <div style={{cursor:'pointer'}} onClick={()=>{
                window.open('/accounting/orders/'+row.user_profile.user_id+'/'+row.member_id,'_axis_fencing_club_order')
            }}>{row.member.name}</div>
        }},  
        {name:'parent',showName:'Family',func:(v,idx,row)=>{
            return <div style={{cursor:'pointer'}} onClick={()=>{
                window.open('/accounting/orders/'+row.user_profile.user_id+'/0','_axis_fencing_club_order')
            }}>{row.user_profile.name}</div>
        }},  
        {name:'product_name',showName:'Product'},
        {name:'minutes',showName:'Private Class Hours'},
        {name:'amount',showName:'Total Fees'}
    ]
    const handleBarChange = (n,v)=>{
        setFields({...fields,[n]:v})
    }
    const handleSubmit = (event)=>{
        setError()        
        loadData(fields.branch_id,fields.from,fields.to,fields.option,orderField.name,orderField.order)
    }
    const handleYear = (event) =>{
        setError()
        setFields({...fields,from:currentYear()})
        loadData(fields.branch_id,currentYear(),fields.to,fields.option,orderField.name,orderField.order)
    }
    return <>
        <Paper sx={{ p: 2, mb: 2 }}>
            <Toolbar style={{ paddingLeft: 2 }}>
                <BranchSelector name="branch_id" sx={{mr:2,maxWidth:"200px"}} label={"Select Location"} onChange={(n,v)=>handleBarChange(n,v.id)} defaultValue={session?.branch_id}/>
        
                <FormControl sx={{ width: '200px', mr: 2 }}>
                    <TextField margin="normal" sx={{ mt: "8px" }} type="date" name="from" value={fields.from} id="from" label="From"
                        InputLabelProps={{ shrink: true }}  onChange={(e)=>handleBarChange("from",e.target.value)} />
                </FormControl>
                <FormControl sx={{ width: '200px', mr: 2 }}>
                    <TextField margin="normal" type="date" sx={{ mt: "8px" }} name="to" value={new Date().toISOString().split('T')[0]} id="to" label="To"
                        InputLabelProps={{ shrink: true }} onChange={(e)=>handleBarChange("to",e.target.value)} />
                </FormControl>
                <SingleSelector items={["Summary","Coach/Product"]} values={[1,0] } defaultValue = {1} onChange={(n,v)=>{handleBarChange("option",v)}} />
                <Button variant='contained' onClick={handleSubmit}>Go</Button>
                <Button variant='contained' color="success" sx={{ml:2}} onClick={handleYear}>Current Member Year</Button>
            </Toolbar>
        </Paper>
        {error && <Alert severity="error">{error}</Alert>}
        <Title>Member's private class hours</Title>
        <MyTable 
            rows={members}
            totalRow = {members.length}
            order={orderField}
            onOrder={handleOrder}
            showPageination = {false}
            checkbox={false}
            headers = {tableHeader}            
        />
    </>
}
export default MemberReport