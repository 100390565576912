
import {  Alert, TextField, Grid, Autocomplete,   Button, Typography } from "@mui/material";
import { useRef,useEffect, useState } from 'react';
import { Add } from "@mui/icons-material";
import { MemberLevel } from "../../../Component/MuiEx";
import BranchSelector from "../../../Utils/BranchSelector";
import { getUserSession } from "../../../Utils/Common";
import { CoachSelect } from "../../../Component/CoachSelector";

const QuickOrder = (props) => {
    const session = getUserSession()
    const [order, setOrder] = useState({count:1,peoples:1,branch_id:session.branch_id})
    const [error,setError] = useState() 
    const [timeStamp,setTimeStamp] = useState()
    
    const coachid = useRef(0)
    const [products,setProducts] = useState(props.products)
    const handleOrderChange = (key, value) => {
        setError()
        setOrder({...order,[key]:value})
    }
    const inputRef = useRef()
    useEffect(()=>{
        inputRef.current ?.focus()
        if(inputRef.current) {
            inputRef.current.focus()                        
        }
        
    },[timeStamp])
    const handleAddOrder = () => {
        setError()     
        if(coachid.current != 0  && order.product.coach_id != coachid.current) {
            setError("The product does not belong to the coach")
            return
        }   
        if(order.product && order.customer && order.count>0 && order.date) {
            props.onAdd && props.onAdd(order)
            setOrder({...order})             
            setTimeStamp(Date.now())
        }else {
            setError("Please check the form")
        }
    }
    const handleCoachSelected = (n,v)=>{
        coachid.current = v.id
        let pp = []
        if(v.id == 0) {
            props.products && setProducts([...props.products])
            return
        }
        for(let i=0;i<props.products?.length;i++) {
            let p = props.products[i]
            if(p.coach_id == v.id) {
                pp.push(p)
            }
        }
        setProducts(pp)
    }
    const setTextInputRef = (element) => {
        inputRef.current = element;
      };
    return <Grid container spacing={2}>
        {error && <Grid item xs={12}><Alert severity="error">{error}</Alert></Grid>}
        <Grid item xs={12}>
            <BranchSelector name="branch_id" onChange={(n,v)=>handleOrderChange(n,v)} defaultValue={session?.branch_id}/>
        </Grid>
        <Grid item xs={12}>
            <TextField  
                type="date" 
                margin="normal" 
                sx={{mt:"0"}}
                InputLabelProps={{ shrink: true }} 
                fullWidth 
                label={"Date"} 
                // value={order.date} 
                onChange={(e) => { handleOrderChange('date', e.target.value) }} 
            />
        </Grid>
        <Grid item xs={12}>
            <CoachSelect name="coach_id" onChange={(n,v)=>handleCoachSelected(n,v)} label={"Select a coach"}/>
        </Grid>
        <Grid item xs={12}>
            <Autocomplete disablePortal id="product-selector"
                options={products}
                autoComplete
                autoHighlight
                autoSelect                
                getOptionLabel={(option) => option.name}                
                onChange={(_event, value) => { handleOrderChange('product', value) }}
                renderInput={(params) => <TextField {...params} value={order.product} label="Product"/>}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField fullWidth type="number" defaultValue={0}                
                label="Minutes (>=1)" onChange={(e) => { handleOrderChange('count', e.target.value) }} />
        </Grid>
        <Grid item xs={12}>
            <TextField fullWidth type="number" defaultValue={1} label="Number of members (>=1)" onChange={(e) => { handleOrderChange('peoples', e.target.value) }} />
            {order.peoples>1 && <Typography variant="subtitle">This is a class deduction. Please add the corresponding number of members to the deduction list.</Typography>}
        </Grid>        
        <Grid item xs={12}>
            <Autocomplete disablePortal id="customer-selector"
                options={props.customers}
                autoComplete
                autoHighlight
                autoSelect                
                getOptionLabel={(option) => MemberLevel(option.level).label +"-" + option.name + "-" + option.id}                
                onChange={(_event, newitem) => { handleOrderChange('customer', newitem) }}                
                renderInput={(params) => <TextField {...params} label="Member" inputRef={setTextInputRef} />}
            />
        </Grid>
        <Grid item xs={12}>
            <TextField fullWidth type="text" label="Note" onChange={(e) => { handleOrderChange('note', e.target.value) }} />
        </Grid>
        <Grid item xs={12}>
            <Button onClick={handleAddOrder} variant="contained"><Add />Add</Button>
        </Grid>

    </Grid>
}

export default QuickOrder