import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { GameCategory } from "../Component/MuiEx"

const GameSelector = (props) => {
    const { defaultValue,onChange,name,label } = props
    
    return <FormControl fullWidth sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id={name}
            onChange={(e) => { onChange && onChange(name,e.target.value)}}
            defaultValue={defaultValue}
            label={label}>
            <MenuItem value={-2}>All Games</MenuItem>
            {GameCategory.map((p, idx) => {                       
                return <MenuItem key={idx} value={idx}>{p}</MenuItem>
            })}
        </Select>
    </FormControl>
}

const MemberStatusSelector = (props) => {
    const { defaultValue,onChange,name,label } = props
    
    return <FormControl fullWidth sx={props.sx}>
        <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
        <Select labelId="demo-simple-select-autowidth-label"
            id={name}
            onChange={(e) => { onChange && onChange(name,e.target.value)}}
            defaultValue={defaultValue}
            label={label}>
            <MenuItem value={-2}>All Members</MenuItem>
            {['Guest','Member','Temporary','Freeze','Inactive'].map((p, idx) => {                       
                return <MenuItem key={idx} value={idx}>{p}</MenuItem>
            })}
        </Select>
    </FormControl>
}

export { GameSelector,MemberStatusSelector}