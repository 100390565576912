import { Alert, Box, Stack } from "@mui/material"
import Uploader from "./Uploader"
import { useState } from "react"

const PhotoView = (props)=>{
    const {value,onChange,width,height,sx} = props
    const [photo,setPhoto] = useState(value)
    const [error,setError] = useState()
    return <Box sx={{...sx,maxHeight:height}}>
        <Stack direction={"row"}>
            <Box sx={{maxHeight:height,overflow:"hidden",border:"1px solid #8888"}}>
                <img src={photo?("/api/resource/photo?file="+photo):"/logo1.jpg"} style={{width:width}}></img>
            </Box>
            <Stack sx={{ml:2}} direction={"column"}>
            {error && <Alert variant="error">{error}</Alert> }
            <Uploader onFailed={(e)=>setError(e)} onUpload={(filename)=>{setPhoto(filename); onChange && onChange(filename)}} />
            </Stack>
            
        </Stack>
        
    </Box>
}

export default PhotoView