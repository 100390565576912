import { useState } from 'react';
import { Button, TextField,  Grid, Box, Typography, Alert, } from '@mui/material';
import {  formToJson} from '../../../Utils/Common';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { MultiSelector, SingleSelector } from '../../../Component/MuiEx';

const EditBranch = (props) => {
    const {data,onSubmit,onClose} = props
    const [fieldErrors, setFieldErrors] = useState()    
    const [error, setError] = useState()
    // const session = getUserSession(apis)

    const handleSubmit = (event) => {
        setFieldErrors()
        setError()
        event.preventDefault();
        const form = new FormData(event.currentTarget);
        const postData = formToJson(form)
        postData.id = data ? data.id:0
        onSubmit && onSubmit(postData,setError,setFieldErrors)
    };
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Box sx={{ marginTop: 12, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Typography component="h1" variant="h5">Add & Edit Location Information</Typography>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }} autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.name} name="name" required fullWidth id="name" label="Location Name"
                                error={fieldErrors && fieldErrors.name ? true : false}
                                helperText={fieldErrors && fieldErrors.name ? fieldErrors.name : ''}
                                autoFocus />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.address} name="address" required fullWidth id="address" label="Branch address"
                                error={fieldErrors && fieldErrors.address ? true : false}
                                helperText={fieldErrors && fieldErrors.address ? fieldErrors.address : ''}
                                autoFocus />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.contacter} name="contacter" fullWidth id="contacter" label="Contact"
                                error={fieldErrors && fieldErrors.contacter ? true : false}
                                helperText={fieldErrors && fieldErrors.contacter ? fieldErrors.contacter : ''}
                                autoFocus />
                        </Grid>      
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.email} name="email" required fullWidth id="email" label="Email"
                                error={fieldErrors && fieldErrors.email ? true : false}
                                helperText={fieldErrors && fieldErrors.email ? fieldErrors.email : ''}
                                autoFocus />
                        </Grid>    
                        
                        <Grid item xs={12} sm={12}>
                            <TextField defaultValue={data?.phone} name="phone" required fullWidth id="phone" label="Phone"
                                error={fieldErrors && fieldErrors.phone ? true : false}
                                helperText={fieldErrors && fieldErrors.phone ? fieldErrors.phone : ''}
                                autoFocus />
                        </Grid> 
                    </Grid>

                    {error && <Alert severity="error">{error}</Alert>}
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 1 }} > Submit </Button>
                    <Button type="button" fullWidth variant="outlined" sx={{ mt: 1, mb: 2 }} onClick={() => { props.onClose && props.onClose(false) }}> Cancel </Button>
                </Box>
            </Box>
        </Container>
    );
}
export default EditBranch